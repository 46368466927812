.home-download-container {
  height: 126px;
}

.home-download-ktv {
  display: block;
  width: 240px;
  margin: 19px auto;
}

.home-download-link {
  display: block;
  width: 103px;
  height: 48px;
  margin: 0 auto;
  margin-top: 32px;
  padding-left: 36px;
  color: #fff;
  font-size: 20px;
  font-family: '黑体';
  line-height: 48px;
  text-decoration: none;
  background: url(//cbshowhot.cdn.changbaimg.com/-/352b55be9028bac2/download.png) no-repeat top left;
  background-size: contain;
}

.home-download-link:hover {
  /* background: url(/images/officialwebsite/index/download.png) no-repeat top left;
  background-size: contain; */
}

.home-display-container {
  position: relative;
  height: 413px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 1600;
  perspective: 1600;
  -webkit-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
}

.carousel {
  min-height: 453px;
}

.slick-slider .home-display-container {
  margin-top: 40px;
}

.slick-dots li button:before {
  color: #fff;
  opacity: .4;
}

.slick-dots li.slick-active button:before {
  color: #fff;
  opacity: 1;
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.slick-slide a {
  display: block;
}

.slick-dots {
  bottom: 10px;
}

.home-display-img {
  position: absolute;
}

.home-display-img1 {
  top: 82px;
  left: -15px;
  -moz-animation: img1 0.7s ease-out 0.5s backwards;
  -o-animation: img1 0.7s ease-out 0.5s backwards;
  -webkit-animation: img1 0.7s ease-out 0.5s backwards;
  animation: img1 0.7s ease-out 0.5s backwards;
}

@keyframes img1 {
  0% {
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes img1 {
  0% {
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.home-display-img2 {
  bottom: 44px;
  left: 155px;
  -moz-animation: img2 0.7s ease-out 0.2s backwards;
  -o-animation: img2 0.7s ease-out 0.2s backwards;
  -webkit-animation: img2 0.7s ease-out 0.2s backwards;
  animation: img2 0.7s ease-out 0.2s backwards;
}

@keyframes img2 {
  0% {
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes img2 {
  0% {
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.home-display-img3 {
  top: -15px;
  left: 308px;
  -moz-animation: img3 0.8s linear backwards;
  -o-animation: img3 0.8s linear backwards;
  -webkit-animation: img3 0.8s linear backwards;
  animation: img3 0.8s linear backwards;
}

@keyframes img3 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  30% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 1;
  }

  40% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
    opacity: 1;
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 1;
  }

  70% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 1;
  }

  80% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    opacity: 1;
  }

  90% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes img3 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  30% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
    opacity: 1;
  }

  40% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 1;
  }

  60% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    opacity: 1;
  }

  70% {
    -webkit-transform: scale(1.06);
    transform: scale(1.06);
    opacity: 1;
  }

  80% {
    -webkit-transform: scale(0.97);
    transform: scale(0.97);
    opacity: 1;
  }

  90% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.home-display-img4 {
  top: 4px;
  left: 508px;
  -moz-animation: img4 0.7s ease-out 0.2s backwards;
  -o-animation: img4 0.7s ease-out 0.2s backwards;
  -webkit-animation: img4 0.7s ease-out 0.2s backwards;
  animation: img4 0.7s ease-out 0.2s backwards;
}

@keyframes img4 {
  0% {
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes img4 {
  0% {
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.home-display-img5 {
  top: -19px;
  left: 653px;
  -moz-animation: img5 0.7s ease-out 0.5s backwards;
  -o-animation: img5 0.7s ease-out 0.5s backwards;
  -webkit-animation: img5 0.7s ease-out 0.5s backwards;
  animation: img5 0.7s ease-out 0.5s backwards;
}

@keyframes img5 {
  0% {
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes img5 {
  0% {
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.home-feature-ul {
  padding: 58px 0;
  text-align: justify;
}

.home-feature-li {
  display: inline-block;
  width: 232px;
  height: 450px;
  overflow: hidden;
  color: #444;
  font-size: 17px;
  text-align: center;
  vertical-align: top;
}

.home-feature-li > img:hover {
  opacity: 0.8;
}

.home-feature-description {
  margin-top: 15px;
  line-height: 17px;
}

.home-feature-place-li {
  width: 100%;
  height: 0;
}

.home-version-container {
  text-align: justify;
}

#home-download a {
  display: inline-block;
  vertical-align: top;
}

.home-justify-div {
  width: 100%;
  height: 60px;
}

.home-icons {
  display: inline-block;
  box-sizing: border-box;
  width: 184px;
  height: 184px;
  padding-top: 36px;
  color: #fff;
  line-height: 184px;
  text-align: center;
  vertical-align: top;
  background: url(//cb.cdn.changbaimg.com/images/officialwebsite/index/home_icons.png?ver=1.0.0) no-repeat center center;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.home-iphone-icon {
  background-position: 0 0;
}

.home-android-icon {
  background-position: -185px 0;
}

.home-ipad-icon {
  background-position: -370px 0;
}

.home-wp-icon {
  background-position: -555px 0;
}

.home-qr-icon {
  background-position: -740px 0;
}

.home-icons:hover {
  -o-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

#qr-container {
  display: none;
  width: 320px;
  height: 355px;
  padding: 15px;
  background: #fff;
}

.home-qr-img {
  display: block;
  width: 300px;
  height: 300px;
  margin: 0 auto;
}

.home-qr-des {
  margin: 10px 0 0 0;
  color: #999;
  font-size: 14px;
  line-height: 18px;
}